import $ from 'jquery';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {_initLanguage, _getLanguage, _setLanguage} from '../lang/LanguageUtils';
import {exportSetHomeBanner} from './homeContents';
import {exportGuideVideo} from './guide';

const walletHrefIndex = 3;
const explorerHrefIndex = 3;

const clickMobileMenu = () => {
  if ($('.headerMenus').hasClass('clicked')) {
    $('.headerMenus').removeClass('clicked');
  } else {
    $('.headerMenus').addClass('clicked');
  }
}

const clickMenu = (menuName, local) => {
  if ($('.headerMenu_' + menuName).hasClass('selected')) return;
  $('.headerMenus')[0].classList.remove('clicked');
  if (menuName == 'OpenSource') return

  if (menuName == 'wallet') menuName = 'wallet0' + (Math.floor((Math.random() * walletHrefIndex) + 1));
  if (menuName == 'explorer') menuName = 'explorer0' + (Math.floor((Math.random() * explorerHrefIndex) + 1));

  if (local) window.location.href = process.env.REACT_APP_URL + menuName
  else window.open("https://" + menuName + process.env.REACT_APP_DOMAIN_URL);
}

const subMenu = (menuName) => {
  if ($('.headerMenu_' + menuName).hasClass('selected')) {
    $('.headerMenu_' + menuName).removeClass('selected');
    // $('.headerMenu_' + menuName).css('color','#ffffff');
    if (window.innerWidth > 768) $('.headerMenu_' + menuName).css('color', '#ffffff');
    else $('.headerMenu_' + menuName).css('color', '#000000');
  } else {
    $("#header").find('.selected').removeClass('selected')
    $('.headerMenu_' + menuName).addClass('selected')
  }
}

const _selectLanguage = (currentLang) => {
  if(localStorage.language == currentLang) return;
  localStorage.language = currentLang;

  $("#lang-lists").children().each((index, el) => {
    if (el.firstChild.textContent == currentLang) {
      $('#lang-text').attr('text-lang', 'lang-text-' + currentLang);
      el.classList.add("selected");
    } else el.classList.remove("selected");
  });
  $('#lang-code').html(currentLang);
  $('html').removeAttr('class');
  $('html').addClass('font-' + localStorage.language);
  exportSetHomeBanner();
  exportGuideVideo();
  _setLanguage();
}

const clickLang = () => {
  if ($("#lang-lists").children().length == 0) {
    const languages = JSON.parse(localStorage.languages);
    for (let languageIndex = 0; languageIndex < languages.length; languageIndex++) {
      const languageItem = document.createElement('li');
      if (localStorage.language == Object.keys(languages[languageIndex])) languageItem.className = 'selected';
      languageItem.setAttribute('setLang', Object.keys(languages[languageIndex]))
      languageItem.addEventListener('click', (el) => {
        _selectLanguage(el.target.attributes['setLang'].textContent);
        $("#lang-lists").hide();
      });

      const languageItemCode = document.createElement('span');
      languageItemCode.className = "lang-code";
      languageItemCode.textContent = Object.keys(languages[languageIndex]);
      languageItemCode.setAttribute('setLang', Object.keys(languages[languageIndex]))
      languageItem.appendChild(languageItemCode);

      const languageItemText = document.createElement('span');
      languageItemText.className = "lang-text";
      languageItemText.setAttribute('setLang', Object.keys(languages[languageIndex]))
      languageItem.appendChild(languageItemText);

      $("#lang-lists").append(languageItem);
    }
  }
  $('.lang-text').each((idx, el) => {
    el.innerHTML = _getLanguage('lang-text-' + el.attributes['setlang'].textContent);
  })
  $("#lang-lists").show();
}

const Header = () => {
  useEffect(() => {
    _initLanguage();

    if(window.innerWidth <= 768) $('.headerMenu_Wallet').attr('text-lang', 'mode-wallet')

    $('#lang-code').html(localStorage.language)
    $('#lang-text').attr('text-lang', 'lang-text-' + localStorage.language);
    $('#lang-text').html(_getLanguage('lang-text-' + localStorage.language));
    $('html').removeAttr('class');
    $('html').addClass('font-' + localStorage.language);
  }, [])
  return (
    <>
      <div id="header">
        <Link to='/home'><p className="icon-krypton"></p></Link>
        <div className='headerMenus'>
          <Link to='/home' text-lang="menu-home" className='headerMenu headerMenu_Home'>Home</Link>
          {/* <div text-lang="menu-home" className='headerMenu headerMenu_Home' onClick={() => clickMenu('home', true)}>Home</div> */}
          <div text-lang="menu-wallet" className='headerMenu headerMenu_Wallet' onClick={() => clickMenu('wallet', false)}>Wallet/ Miner</div>
          <div className='headerMenu headerMenu_Explorer' onClick={() => clickMenu('explorer', false)}>Explorer</div>
          <Link to='/guide' text-lang="mode-guide" className='headerMenu headerMenu_How'>Get Coin</Link>
          {/* <div text-lang="mode-guide" className='headerMenu headerMenu_How' onClick={() => clickMenu('guide', true)}>Get Coin</div> */}
          {/* <div className='headerMenu headerMenu_How' onClick={() => subMenu('How')}>
            How to get
            <div className='headerSubMenu'>
              <div className="headerSubMenu_Mining" onClick={() => clickMenu('mining', true)}>Mining</div>
              <div className="headerSubMenu_FromFEE" onClick={() => clickMenu('fromfee', true)}>From FEE</div>
              <div className="headerSubMenu_Donation" onClick={() => clickMenu('donation', true)}>Donation</div>
            </div>
          </div> */}
          <Link to='/repository' text-lang="menu-repository" className='headerMenu headerMenu_Repository'>Repository</Link>
          {/* <div text-lang="menu-repository" className='headerMenu headerMenu_Repository' onClick={() => clickMenu('repository', true)}>Repository</div> */}
          {/* <div className='headerMenu headerMenu_OpenSource' onClick={() => clickMenu('OpenSource', false)} disabled><del>Open Source</del></div> */}
        </div>
        <div id='lang-ui'>
          <button id='lang-button' onClick={() => clickLang()}>
            <span id='lang-code'>EN</span>
            <span text-lang="lang-text-EN" id='lang-text'>English</span>
          </button>
          <ul id='lang-lists'></ul>
          <button id='menu-mobile-button' onClick={() => clickMobileMenu()}></button>
        </div>
      </div>
    </>
  );
};

export default Header;