import React from 'react';
import { Link } from 'react-router-dom';

const walletHrefIndex = 3;
const explorerHrefIndex = 3;

const clickMenu = (menuName) => {
  if (menuName == 'wallet') menuName = 'https://wallet0' + (Math.floor((Math.random() * walletHrefIndex) + 1)) + process.env.REACT_APP_DOMAIN_URL;
  else if (menuName == 'explorer') menuName = 'https://explorer0' + (Math.floor((Math.random() * explorerHrefIndex) + 1)) + process.env.REACT_APP_DOMAIN_URL;
  else if (menuName == 'youtube') menuName = localStorage.language == 'VI' ? 'https://www.youtube.com/@bitcoin-krypton-vietnam' : 'https://www.youtube.com/@bitcoin-krypton-global';
  else if (menuName == 'discode') menuName = 'https://discord.gg/NyTkevAadx';
  else if (menuName == 'telegram') menuName = 'https://t.me/Bitcoin_Krypton_Community';
  else if (menuName == 'facebook') menuName = 'https://www.facebook.com/bitcoinkrypton/';
  else if (menuName == 'instagram') menuName = 'https://www.instagram.com/btck_global/';
  else if (menuName == 'threads') menuName = 'https://www.threads.net/@btck_global';
  else if (menuName == 'x') menuName = 'https://x.com/bitcoin_krypton';

  window.open(menuName);
}

const Footer = () => {
  return (
    <>
      <div id="footer">
        <p className="icon-krypton"></p>
        <div className="footer-list footer-info">
          <p>INFO</p>
          <div className="footer-list-item">
            <Link to='/home' text-lang="menu-home">Home</Link>
            <a text-lang="menu-wallet" onClick={() => clickMenu('wallet')}>Wallet/ Miner</a>
            <a onClick={() => clickMenu('explorer')}>Explorer</a>
            <Link to='/guide' text-lang="mode-guide">Guide</Link>
            <Link to='/repository' text-lang="menu-repository">Repository</Link>
          </div>
        </div>
        <div className="icon-move-top" onClick={() => window.scroll({ top: 0 })}></div>
        <div className="footer-list footer-sns">
          <p>SNS</p>
          <div className="footer-list-item">
            <div className='footer-list-item-youtube' onClick={() => clickMenu('youtube')}>Youtube</div>
            <div className='footer-list-item-discode' onClick={() => clickMenu('discode')}>Discode</div>
            <div className='footer-list-item-telegram' onClick={() => clickMenu('telegram')}>Telegram</div>
            <div className='footer-list-item-facebook' onClick={() => clickMenu('facebook')}>Facebook</div>
            <div className='footer-list-item-instagram' onClick={() => clickMenu('instagram')}>Instagram</div>
            <div className='footer-list-item-threads' onClick={() => clickMenu('threads')}>Threads</div>
            <div className='footer-list-item-x' onClick={() => clickMenu('x')}>X</div>
          </div>
        </div>
        <div className="icon-background"></div>
      </div>
    </>
  );
};

export default Footer;