import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';

import Root from './pages/root';
import Header from './pages/header';
import Footer from './pages/footer';

function App() {
  localStorage.languages = JSON.stringify([{'EN':'English'}, {'KO':'Korean'}, {'VI':'Vietnamese'}, {'ZH':'Chinese'}]);
  localStorage.language = '';
  localStorage.webMode = 'wallet';
  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route path="*" element={<Root/>}/>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
