import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import $, { css } from 'jquery';
import {_getLanguage, _setLanguage} from '../lang/LanguageUtils';


var homeBanner_interval
var homeHeaderImgIndex = 1
const homeHeaderImgCount = 3

const walletHrefIndex = 3;
const homeTailDetail = [
    "The initial original source code, including the genesis block, was created in the past and cannot be changed by any external force in the future.",
    "This is a declaration that the original source code cannot be changed for any reason.<br/>However, the freedom to transplant to a new program language in a form that is 100% reversible and compatible with the original source code is allowed.",
    "Newton's apple is the discovery of a law of nature. An apple falls from above, and this is not a matter of good or bad, but a matter of law.<br/> The rules set in the early days of Bitcoin Krypton cannot be changed for any reason.",
    "This homepage was created for the purpose of the initial settlement of Bitcoin Krypton, and it will only exist for a short time.<br/> Since the domain is an asset belonging to a third party such as an individual or organization, a domain that can affect the existence of Bitcoin Krypton will only be operated for a period of 10 years and will not be used after that.",
    "The seed node responsible for broadcasting the mainnet, including the genesis block, will depend on the internet domain for the time being (10 years).<br/> However, the server information being broadcasted will no longer be provided in the future, and the improvement of the program that automates this is aimed at future developers.",
    "The original seed node will disappear, and the task will be left to future generations to operate the blockchain without the seed node.",
    "All places that store wallets, explorers, source codes, white papers, etc. provided initially should not be trusted because the management entity may disappear, and all proofs should be based on the contents announced through bitcoinkrypton.org on November 1, 2024.",
    "Bitcoin Krypton is a P2P financial program that has no updates and will not have a management entity at any point in the future, and this is talking about the birth of the most perfect blockchain-based cryptocurrency.",
]

const openHrefButton = (path) => {
    window.open("https://wallet0" + (Math.floor((Math.random() * walletHrefIndex) + 1)) + process.env.REACT_APP_DOMAIN_URL + path) ;
}

const displayHomeBodyDetail = () => {
    if ($("#home-content-body-read-more").text() == _getLanguage('mining-text-more')) {
        $("#home-content-body-show").show();
        $("#home-content-body-hide").hide();
        $("#home-content-body-read-more").html(_getLanguage('mining-text-hide'));
    } else {
        $("#home-content-body-show").hide();
        $("#home-content-body-hide").show();
        $("#home-content-body-read-more").html(_getLanguage('mining-text-more'));
    }
}

const displayHomeTailDetail = () => {
    $('.home-content-tail').empty();
    const homeContentTailTitle = document.createElement('div');
    homeContentTailTitle.className = "home-content-tail-title";
    homeContentTailTitle.textContent = "The premise for this is as follows:";
    homeContentTailTitle.setAttribute('text-lang', 'home-content-tail-title');
    homeContentTailTitle.innerHTML = _getLanguage('home-content-tail-title');
    $('.home-content-tail').append(homeContentTailTitle);
    
    for (let index = 0; index < homeTailDetail.length; index++) {
        const homeContentTailDetail = document.createElement('div');

        const homeContentTailIndex = document.createElement('div');
        homeContentTailIndex.className = "home-content-tail-index";
        homeContentTailIndex.innerHTML = index + 1;

        const homeContentTailText = document.createElement('div');
        homeContentTailText.className = "home-content-tail-text";
        homeContentTailText.innerHTML = homeTailDetail[index];
        homeContentTailText.setAttribute('text-lang', 'home-content-tail-text'+ index);
        homeContentTailText.innerHTML = _getLanguage('home-content-tail-text'+ index);

        homeContentTailDetail.append(homeContentTailIndex);
        homeContentTailDetail.append(homeContentTailText);

        $('.home-content-tail').append(homeContentTailDetail);
    }
}
export const exportSetHomeBanner = () =>  {
    if(window.innerWidth <= 768) return;
    setHomeBanner();
}
const setHomeBanner = () => {
    let donationType = '';
    let imageType = 'png';
    if(homeHeaderImgIndex == 1) {
        imageType = 'gif';
    } else if(homeHeaderImgIndex == 3) {
        if(!localStorage.donationPrice) localStorage.donationPrice = 1;
        donationType = '-' + localStorage.donationPrice
    }
    $('.home-content-header-banner').css('background-image', "url('/img/home-banner-"+localStorage.language+"-img"+ homeHeaderImgIndex + donationType +"."+imageType+"')");
}

const displayHomeHeaderDetail = () => {
    if(window.innerWidth <= 768) return;
    clearInterval(homeBanner_interval);
    setHomeBanner();
    homeBanner_interval = setInterval(()=>{
        homeHeaderImgIndex < homeHeaderImgCount ? homeHeaderImgIndex ++ : homeHeaderImgIndex = 1;
        setHomeBanner();
    }, 8000);
}
const moveHeaderBanner = (direction) => {
    if(direction == 'left') {
        homeHeaderImgIndex > 1 ? homeHeaderImgIndex-- : homeHeaderImgIndex = 3;
    } else {
        homeHeaderImgIndex < homeHeaderImgCount ? homeHeaderImgIndex++ : homeHeaderImgIndex = 1;
    }
    displayHomeHeaderDetail();
}
const getPathFromHomeBannner = () => {
    if(homeHeaderImgIndex == 1) return '/wallet';
    if(homeHeaderImgIndex == 2) return '/home';
    else if(homeHeaderImgIndex == 3) return "/guide";
}
const HomeContents = () => {

    useEffect(() => {
        _setLanguage()
        displayHomeHeaderDetail();
        displayHomeTailDetail();
        $(".headerMenu_Home").addClass("selected");
        $(".headerMenu_How").removeClass("selected");
        $(".headerMenu_Repository").removeClass("selected");
    }, [])

    const navigate = useNavigate();

    return (
        <>
            <div id="home-contents">
                <div className='home-content-header'>
                    <div className='home-content-header-button-left'>
                        <button onClick={() => moveHeaderBanner('left')}/>
                    </div>
                    <div className='home-content-header-banner' onClick={() => navigate(getPathFromHomeBannner())}></div>
                    {/* <div className='home-content-header-banner' onClick={() => getPathFromHomeBannner()}></div> */}
                    <div className='home-content-header-button-right'>
                        <button onClick={() => moveHeaderBanner('right')}/>
                    </div>
                    <div className='home-content-header-button'>
                        <button text-lang='home-content-header-mining' className='home-content-header-button-mining home-content-button' onClick={() => openHrefButton('?miner')}>
                            Start Mining
                        </button>
                        <button text-lang='home-content-header-wallet' className='home-content-header-button-wallet home-content-button' onClick={() => openHrefButton('?wallet')}>
                            Generate Wallet
                        </button>
                    </div>
                </div>
                <div className='home-content-body'>
                    <div className='left'>
                        <iframe src="https://www.youtube.com/embed/wbAGCLmCADk?si=6-STvIvVvFmk_IBN"
                            title="Bitcoin Krypton Video" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                        </iframe>
                    </div>
                    <div className='right'>
                        <div text-lang='home-content-body-top'>
                            Bitcoin Krypton was developed to fully fulfill its original purpose of creating a peer-to-peer financial program that can operate without the intervention of a trusted third party, in accordance with the original Bitcoin whitepaper.
                        </div>
                        <div id="home-content-body-hide">...</div>
                        <div text-lang='home-content-body-bottom' id="home-content-body-show">
                            <br/><br/>
                            The Bitcoin blockchain, which has been in operation since 2009, has had SegWit applied since 2017, which has severely undermined Satoshi Nakamoto's slogan of "no third-party intervention."<br/><br/>
                            The Lightning Network is a third-party network that has no connection to Bitcoin.<br/><br/>
                            As a result, Bitcoin has lost its value.<br/><br/>
                            The beginning of a blockchain is inevitably created by some external force (intervention by a third party). However, there should be no intervention by any external force after that, and this is derived from a philosophical consideration of what makes the blockchain complete.<br/><br/>
                            Bitcoin Krypton is a sustainable blockchain that does not require any future updates and without the intervention of a trusted third party.
                        </div>
                        <button text-lang='mining-text-more' id="home-content-body-read-more" className='home-content-button' onClick={() => displayHomeBodyDetail()}>
                            Read more
                        </button>
                    </div>
                </div>
                <div className='home-content-tail'>
                </div>
            </div>
        </>
    );
};

export default HomeContents;